import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import Media from "components/blocks/media"
import { renderClasses } from "utils/renderClasses"
import "./SplitContentWithRichText.scss"

const SplitContentWithRichText = ({
  action,
  backgroundColor = "ivory",
  className,
  dashedLine,
  media,
  reverse,
  text,
  title,
}) => {
  return (
    <div className="split-content-with-rich-text-wrapper">
      {/* Conditionally Render Dashed Line */}
      {dashedLine && (
        <div className="split-content-with-rich-text__dashed-line" />
      )}
      <Layout
        className={renderClasses(`g-split-content ${className}`, [
          [backgroundColor, `--${backgroundColor}`],
        ])}
        layout="custom"
        mobileLayout="ACB"
        noPadding={dashedLine}
        reverse={reverse}
        textLockup={{
          title,
          action,
          text,
        }}
      >
        <Media {...media} />
      </Layout>
    </div>
  )
}
SplitContentWithRichText.props = {
  action: PropTypes.shape({
    accessibilityLabel: PropTypes.string,
    color: PropTypes.string,
    href: PropTypes.string.isRequired,
    isOutlined: PropTypes.bool,
    isSmall: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }),
  backgroundColor: PropTypes.oneOf([
    "ivory",
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "darkBlue",
    "darkPink",
  ]),
  className: PropTypes.string,
  dashedLine: PropTypes.bool,
  media: PropTypes.shape(Media.props),
  mediaPosition: PropTypes.oneOf(["left", "right"]),
  richText: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  title: PropTypes.string.isRequired,
}

SplitContentWithRichText.propTypes = SplitContentWithRichText.props

export default SplitContentWithRichText
