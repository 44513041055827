import React from "react"
import PropTypes from "prop-types"
import COMPONENTS from "utils/componentConstants"
import SECTIONS from "utils/sectionConstants"
import SplitSection from "components/sections/splitSection"
import SplitContentWithRichText from "components/sections/splitContentWithRichText"
import InsuranceBanner from "components/sections/insuranceBanner"
import SectionCards from "components/sections/sectionCards"
import Wrapper from "components/sections/wrapper"
import FeatureCards from "components/sections/featureCards"
import Faq from "components/sections/faq"
const WholeChildHomePage = ({ sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case COMPONENTS.SplitContent:
        return <SplitSection {...section} key={i} />
      case COMPONENTS.SplitContentWithRichText:
        return <SplitContentWithRichText {...section} key={i} />
      case COMPONENTS.InsuranceBanner:
        return (
          <InsuranceBanner
            className="payment-options-banner"
            displayMode="logos"
            key={i}
            {...section}
          />
        )
      case SECTIONS.SectionCards:
        return <SectionCards {...section} key={i} />
      case SECTIONS.WrapperSection:
        return <Wrapper {...section} />
      case SECTIONS.FeatureCards:
        return <FeatureCards {...section} />
      case COMPONENTS.Features:
        return <Faq {...section} key={i} />
      default:
        return null
    }
  })

WholeChildHomePage.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(SplitSection.props)])
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

WholeChildHomePage.propTypes = WholeChildHomePage.props

export default WholeChildHomePage
