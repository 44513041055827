import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./featureCards.scss"
import RichText from "components/elements/richText"
import Title from "components/elements/Title"
import Accordion from "../accordion"
const FeatureCards = ({
  backgroundColor,
  className,
  contentTitle,
  featureCards,
  reverse,
  schedules,
}) => {
  const sectionId = contentTitle?.details?.json?.content[0]?.content[0]?.value
    ?.replace(/\s+/g, "-")
    .toLowerCase()

  return (
    <div
      className={renderClasses(`g-feature-cards ${className}`, [
        [backgroundColor, `--${backgroundColor}`],
      ])}
      id={sectionId}
    >
      <div className="g-feature-cards__wrapper">
        <RichText {...contentTitle} className="section-text" />
        <div
          className={renderClasses("g-feature-cards__grid", [
            [reverse, "--reverse"],
          ])}
        >
          {featureCards.map((card, i) => (
            <div
              className={renderClasses(
                `feature-card ${
                  i === 0 ? "feature-card--big" : "feature-card--small"
                }`,
                [
                  [reverse, "--reverse"],
                  [card.backgroundColor, `--${card.backgroundColor}`],
                ]
              )}
              key={i}
            >
              {card.media?.image?.file?.url && (
                <img
                  alt={card.media.image.alt || ""}
                  className="feature-card__icon"
                  src={card.media.image.file.url}
                />
              )}
              <div className="feature-card__title">
                <Title {...card.title} />
              </div>
              <div className="g-card__content">
                {card.text?.json && (
                  <RichText className="card-text" richText={card.text} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Accordion
        cards={schedules}
        className="g-schedule-card"
        isAccordion={true}
        layout="accordion"
      />
    </div>
  )
}

FeatureCards.propTypes = {
  ...Accordion.props,
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
  ]),
  className: PropTypes.string,
  contentTitle: PropTypes.object,
  featureCards: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      media: PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
          alt: PropTypes.string,
        }),
      }),
      text: PropTypes.shape({
        json: PropTypes.object,
      }),
    })
  ),
  reverse: PropTypes.bool,
}

export default FeatureCards
